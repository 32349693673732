<template>
  <div class="returntoresults_box">
    <div class="returntoresults_top d-flex align-items-center justify-content-between">
      <h4 class="d-flex align-items-center">
        <span>
          <img src="/assets/sabre-img/airplane1.png" v-if="isBack">
          <img src="/assets/sabre-img/airplane.png" v-else>
        </span>{{$t("sabre.search-result.box.flight-to-day-name", {day: departureDate, dayName: departureDayName, dest: arrivalCityName})}}
      </h4>
      <div class="box_one_logo d-flex align-items-center">
        <h5>{{airlineName}}</h5>
        <div class="airlogo">
          <img :src="airlineLogo" v-if="!unconfirmedUnauthorized"/>
        </div>
      </div>
    </div>
    <div class="returntoresults_midel d-flex justify-content-between">
      <div class="box_two d-flex align-items-center justify-content-between">
        <div class="box_two_text">
          <h4>{{`${departureTime} ${departureCityCode}`}}</h4>
        </div>
        <div class="box_two_midel">
          <p>{{$t("sabre.search-result.box.flight-hour-minute", elapsedTime)}}</p>
          <div class="line"><span v-if="data.excerpt.stops.indexOf(1) > -1"></span></div>
          <p>{{$t("sabre.search-result.box.direct")}}</p>
        </div>
        <div class="box_two_text">
          <h4>{{`${arrivalTime} ${arrivalCityCode}`}}</h4>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end returntoresults_infobox">
        <div class="imgifo">
          <img src="/assets/sabre-img/trolley_allow.png" alt="#" v-if="allowCabinBaggage">
          <img src="/assets/sabre-img/trolley_disallow.png" alt="#" v-else>
        </div>
        <div class="imgifo">
          <img src="/assets/sabre-img/Suitcase_allow.png" alt="#" v-if="allowCheckinBaggage">
          <img src="/assets/sabre-img/Suitcase_disallow.png" alt="#" v-else>
        </div>
      </div>
    </div>

    <div class="returntoresults_footer d-flex justify-content-end" v-if="kind==='changeFlight'">
      <a href="javascript:void(0)" @click="() => $bvModal.show('detailFlightMobileModal')" class="Closedetail_btn">{{$t("sabre.buttons.more-detail")}}</a>
    </div>

  </div>
</template>

<script>
import searchResultBoxMixin from '../../searchResultBox/searchResultBoxMixin';

export default {
  name: 'mobile-one-result-box',
  mixins: [searchResultBoxMixin],
};
</script>
